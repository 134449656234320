export default {
    features: {
        RAWSIGN: false,
        SWIM: true,
        KEYSIGN: true, // Private/Public Key Signing
        HSM: true,
        LEDGER: true, // USB Ledger Device Signing
    },
    overrides: {
        algorand: { KEYSIGN: true, RAWSIGN: false, SWIM: false, HSM: false, LEDGER: false },
        aptos: { RAWSIGN: true, KEYSIGN: false, SWIM: false, HSM: false, LEDGER: false },
        solana: { RAWSIGN: true, KEYSIGN: false, SWIM: false, HSM: false, LEDGER: false },
        ink: { RAWSIGN: true },
    },
};
