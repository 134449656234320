export default {
    features: {
        available: true,
    },
    overrides: {
        algorand: { available: false },
        aptos: { available: false },
        solana: { available: false },
    },
};
