<template>
  <b-card class="border-light ml-5 mt-3 p-3">
    <div v-if="!ledgerAddress">
      <!-- Connect Ledger Button -->
      <b-button
        :disabled="!isLedgerEnabled || multipleMultiSigTransactionsSelected || hasMultiSigWalletPendingSignatures"
        class="w-100"
        variant="primary"
        @click="connectLedger"
      >
        {{ $t('signatures.signTransactionModal.button.connectLedger') }}
      </b-button>
    </div>

    <div
      v-else
      class="ledger-info-container"
    >
      <!-- Ledger Address Display -->
      <div class="ledger-details">
        <label>{{ $t('signatures.signTransactionModal.message.ledgerConnected') }}:</label>
        <span class="ledger-address">{{ shortenedAddress }}</span>
      </div>

      <!-- Disconnect Ledger Button -->
      <b-button
        class="disconnect-button"
        variant="danger"
        @click="disconnectLedger"
      >
        {{ $t('signatures.signTransactionModal.button.disconnectLedger') }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BlockchainTransactionSigner } from '@securitize/blockchain-transaction-signer';

export default {
  name: 'CPLedgerSign',
  props: {
    isLedgerEnabled: {
      type: Boolean,
      required: true,
    },
    blockchainType: {
      type: String,
      required: true,
    },
    multipleMultiSigTransactionsSelected: {
      type: Boolean,
      default: false,
    },
    hasMultiSigWalletPendingSignatures: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ledgerAddress: '',
    };
  },
  computed: {
    // Truncate address for better UI display
    shortenedAddress() {
      if (!this.ledgerAddress) return '';
      return `${this.ledgerAddress.slice(0, 6)}...${this.ledgerAddress.slice(-6)}`;
    },
  },
  methods: {
    async connectLedger() {
      try {
        this.ledgerAddress = await BlockchainTransactionSigner.getLedgerAddress(this.blockchainType);
        this.$emit('update:ledgerAddress', this.ledgerAddress);
      } catch (error) {
        console.error('Ledger connection error:', error);
        this.$emit('ledgerError', error.message);
      }
    },
    disconnectLedger() {
      this.ledgerAddress = ''; // Reset address
      this.$emit('update:ledgerAddress', ''); // Emit reset event
    },
  },
};
</script>

<style scoped>
.ledger-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.ledger-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f9fa;
  padding: 8px 12px;
  border-radius: 6px;
  width: 100%;
  text-align: center;
}

.ledger-address {
  font-family: monospace;
  font-size: 12px; /* Reduced size */
  color: #555;
  margin-top: 5px;
  background: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: fit-content;
}

.disconnect-button {
  margin-top: 10px;
  width: 100%;
}
</style>
