<template>
  <b-card class="border-light ml-5 mt-3">
    <cp-input
      v-model="signerAddress"
      :disabled="!isPrivateKeySignatureType || multipleMultiSigTransactionsSelected || hasMultiSigWalletPendingSignatures"
      :placeholder="$t('signatures.signTransactionModal.input.walletAddress.placeholder')"
      :validate="isPrivateKeySignatureType ? 'required' : ''"
      name="signerAddress"
    />

    <cp-input
      v-model="privateKey"
      :disabled="!isPrivateKeySignatureType || multipleMultiSigTransactionsSelected || hasMultiSigWalletPendingSignatures"
      :placeholder="$t('signatures.signTransactionModal.input.privateKey.placeholder')"
      :validate="isPrivateKeySignatureType ? 'required' : ''"
      class="mt-3"
      name="privateKey"
    />

    <div class="pt-3">
      <small>
        <i class="ion ion-ios-information-circle-outline" />
        {{ $t('signatures.signTransactionModal.message.privateKeyCaution') }}
      </small>
    </div>
  </b-card>
</template>

<script>
import CpInput from '@/components/common/standalone-components/inputs/cp-input.vue';

export default {
  name: 'CPKeySign',
  components: { CpInput },
  props: {
    isKeySignMultiSignature: {
      type: Boolean,
      required: true,
    },
    isKeySignSingleSignature: {
      type: Boolean,
      required: true,
    },
    multipleMultiSigTransactionsSelected: {
      type: Boolean,
      default: false,
    },
    hasMultiSigWalletPendingSignatures: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      signerAddress: '',
      privateKey: '',
    };
  },
  computed: {
    isPrivateKeySignatureType() {
      return (this.isKeySignSingleSignature || this.isKeySignMultiSignature);
    },
  },
  watch: {
    signerAddress(newVal) {
      this.$emit('update:signerAddress', newVal);
    },
    privateKey(newVal) {
      this.$emit('update:privateKey', newVal);
    },
  },
};
</script>
