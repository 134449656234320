<template>
  <cp-general-modal
    ref="modalLogic"
    :hide-footer="working"
    :ok-disabled="isLoading || multipleMultiSigTransactionsSelected || hasMultiSigWalletPendingSignatures"
    :ok-text="$t('signatures.signTransactionModal.button.sign.now')"
    :title="$t('signatures.signTransactionModal.title')"
    scrollable
    @onCancel="$emit('onCancel')"
    @onOk="submitTransactions"
    @shown="init"
  >
    <!-- Loading Spinner -->
    <div class="text-center">
      <b-spinner
        v-if="isLoading"
        big
        class="align-middle"
      />
    </div>

    <!-- Multiple MultiSig Warning -->
    <div
      v-if="!isLoading && multipleMultiSigTransactionsSelected"
      class="alert alert-warning mx-4 mt-4"
    >
      {{ $t('signatures.signTransactionModal.message.multipleMultiSigTransactionsSelected') }}
    </div>


    <!-- Transaction Details -->
    <div
      v-if="!isLoading"
      class="card border-light mx-4 mt-4"
    >
      <div v-if="transactions.length === 1">
        <b-card>
          <strong>{{ $t('signatures.field.id') }}</strong>{{ transactions[0].id }}
        </b-card>
        <b-card>
          <strong>{{ $t('signatures.field.type') }}</strong>{{ transactions[0].type }}
        </b-card>
        <b-card>
          <strong>{{ $t('signatures.field.description') }}</strong>
          <description :description-text="transactions[0].description" />
        </b-card>
        <b-card v-if="displayMultiSigWallets">
          <strong>{{ $t('signatures.field.signatureCount') }}</strong>
          <description :description-text="signatureCount" />
        </b-card>
      </div>
      <div v-else>
        <b-card>
          {{ $t('signatures.signTransactionModal.message.signTransactionsIds', [getTransactionsIds]) }}
        </b-card>
      </div>
    </div>

    <!-- Signature Type Selection -->
    <b-card
      v-if="!isLoading"
      class="border-light mx-4 mt-4"
    >
      <label class="form-label">
        {{ $t('signatures.signTransactionModal.select.signatureType.text') }}
      </label>
      <cp-select
        v-model="selectedSignatureType"
        :disabled="signatureTypeDisabled"
        :options="getSignatureTypes"
        name="signatureTypeSelect"
        @input="signatureTypeChanged"
      />
    </b-card>

    <!-- MultiSig Wallet Selection -->
    <b-card
      v-if="displayMultiSigWallets && !isLoading"
      class="border-light mx-4 mt-4"
    >
      <label class="form-label">
        {{ $t('signatures.signTransactionModal.select.multiSigWallet.text') }}
      </label>
      <cp-select
        v-model="selectedMultiSigWallet"
        :disabled="disableMultiSigWalletSelect || !isSelectedSignatureTypeMultisig"
        :options="multiSigWalletOptions"
        :placeholder="true"
        name="multiSignatureSelect"
        @input="multiSigChanged"
      />
    </b-card>

    <!-- Signature Method Selection -->
    <b-card
      v-if="!isLoading"
      class="border-light mx-4 mt-4 p-3"
    >
      <label class="form-label">
        {{ $t('signatures.signTransactionModal.signatureMethod.text') }}
      </label>

      <!-- Hardware Security Module Signature -->
      <b-form-radio
        v-if="shouldDisplayHSM"
        v-model="selectedOptionToSign"
        :disabled="isHSMDisabled"
        class="mb-3"
        value="HSM"
      >
        {{ $t("signatures.signTransactionModal.signatureMethod.method.hsm") }}
      </b-form-radio>

      <CPHsm
        v-if="selectedOptionToSign === 'HSM'"
        ref="hsmComponent"
        :operator-keys="operatorHSMKeys"
        :transactions="tList"
        class="mt-2 p-2 border rounded shadow-sm"
        @onSuccess="onSuccess"
      />

      <!-- SWIM -->
      <b-form-radio
        v-if="shouldDisplaySWIM"
        v-model="selectedOptionToSign"
        class="mt-3 mb-3"
        value="SWIM"
      >
        {{ $t("signatures.signTransactionModal.signatureMethod.method.package") }}
      </b-form-radio>

      <CPSwim
        v-if="selectedOptionToSign === 'SWIM'"
        ref="swimComponent"
        :transactions="tList"
        class="ml-4 mt-2 p-2 border rounded shadow-sm"
        @onSuccess="onSuccess"
      />

      <!-- Fireblocks -->
      <b-form-radio
        v-if="shouldDisplayRawSign"
        v-model="selectedOptionToSign"
        class="mt-3 mb-3"
        value="RAWSIGN"
      >
        {{ $t("signatures.signTransactionModal.signatureMethod.method.fireblocks") }}
      </b-form-radio>

      <CPFireblocks
        v-if="selectedOptionToSign === 'RAWSIGN'"
        ref="fireblocksComponent"
        class="ml-4 mt-2 p-2 border rounded shadow-sm"
        @onSuccess="onSuccess"
      />

      <!-- Private/Public Key (KEYSIGN) -->
      <b-form-radio
        v-if="shouldDisplayKeySign"
        v-model="selectedOptionToSign"
        class="mt-3 mb-3"
        value="KEYSIGN"
      >
        {{ $t("signatures.signTransactionModal.signatureMethod.method.walletAddressPrivateKey") }}
      </b-form-radio>

      <CPKeySign
        v-if="selectedOptionToSign === 'KEYSIGN'"
        :has-multi-sig-wallet-pending-signatures="hasMultiSigWalletPendingSignatures"
        :is-key-sign-multi-signature="isKeySignMultiSignature"
        :is-key-sign-single-signature="isKeySignSingleSignature"
        :multiple-multi-sig-transactions-selected="multipleMultiSigTransactionsSelected"
        class="ml-4 mt-2 p-2 border rounded shadow-sm"
        @update:signerAddress="signerAddress = $event"
        @update:privateKey="privateKey = $event"
      />

      <!-- Ledger Signing -->
      <b-form-radio
        v-if="shouldDisplayLedger"
        v-model="selectedOptionToSign"
        class="mt-3 mb-3"
        value="LEDGER"
      >
        {{ $t("signatures.signTransactionModal.signatureMethod.method.usbLedgerDevice") }}
      </b-form-radio>

      <CPLedgerSign
        v-if="selectedOptionToSign === 'LEDGER'"
        :blockchain-type="blockchainType"
        :has-multi-sig-wallet-pending-signatures="hasMultiSigWalletPendingSignatures"
        :is-ledger-enabled="shouldDisplayLedger"
        :multiple-multi-sig-transactions-selected="multipleMultiSigTransactionsSelected"
        class="ml-4 mt-2 p-2 border rounded shadow-sm"
        @ledgerError="errors.push($event)"
        @update:ledgerAddress="ledgerAddress = $event"
      />
    </b-card>

    <div
      v-if="working"
      class="mx-4 mt-4"
    >
      <label>{{ $t('signatures.signTransactionModal.label.signingTransactions') }}</label>
      <b-progress
        v-if="working"
        :max="bulkTransactionsEnabled ? 1 : tList.length"
        class="my-3"
        show-value
      >
        <b-progress-bar
          :value="signed + 1"
          variant="primary"
        >
          {{ $t('signatures.signTransactionModal.label.signing', {signed: signed + 1, total: tList.length}) }}
        </b-progress-bar>
      </b-progress>
    </div>
    <div
      v-if="errors.length"
      class="card-body"
    >
      <cp-error-notices :errors="errors" />
    </div>
  </cp-general-modal>
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import async from 'async';
import { BlockchainTransactionSigner } from '@securitize/blockchain-transaction-signer';
import Description from './description';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import { signatureTypes } from '../options';
import { i18nKeyListConvert } from '~/utilities/i18n-util';
import CpSelect from '@/components/common/standalone-components/inputs/cp-select';
import CPHsm from '@/pages/_idIssuer/signatures/components/hsm-components/hsm-components';
import CPSwim from '@/pages/_idIssuer/signatures/components/swim-components/swim-component';
import CPFireblocks from '@/pages/_idIssuer/signatures/components/fireblocks-components/fireblocks-component';
import { BULK_DS_PROTOCOL_TRANSACTIONS_FEATURE_FLAG } from '@/pages/_idIssuer/signatures/issuerClientConfigurations';
import CPKeySign from '@/pages/_idIssuer/signatures/components/key-sign-components/key-sign-component.vue';
import CPLedgerSign from '@/pages/_idIssuer/signatures/components/ledger-sign-components/ledger-sign-component.vue';
import signingFeatures from '@/pages/_idIssuer/signatures/signingFeatures';
import multiSigChainAvailability from '@/pages/_idIssuer/signatures/multiSigChainAvailability';
import SigningUtils from '@/pages/_idIssuer/signatures/components/swim-components/signing-utils';
import CpErrorNotices from '@/components/common/error-notices.vue';

export default {
  name: 'CpSignTransactionModal',
  components: {
    CpErrorNotices,
    CPLedgerSign,
    CPKeySign,
    CPHsm,
    CPSwim,
    CpSelect,
    Description,
    CpGeneralModal,
    CPFireblocks,
  },
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      working: false,
      signed: 0,
      errors: [],
      tList: [],
      privateKey: '',
      signerAddress: '',
      signatureTypes,
      signatureTypeDisabled: false,
      selectedSignatureType: this.singleSignature,
      displayMultiSigWallets: false,
      selectedOptionToSign: 'HSM',
      operatorHSMKeys: [],
      deploymentId: null,
      multiSigWalletOptions: null,
      selectedMultiSigWallet: null,
      signatureCount: '',
      multipleMultiSigTransactionsSelected: false,
      multiSigWalletHasPendingSignatures: false,
      multiSigStatus: null,
      multiSigWallets: null,
      displayLastSignMessage: false,
      isLoading: true,
      bulkTransactionsEnabled: false,
      clientTransactionId: null,
      multiSignature: 'multiSignature',
      singleSignature: 'singleSignature',
      ledgerAddress: '',
    };
  },
  computed: {
    ...mapState('configToken', ['deploymentToken']),
    ...mapGetters('issuersInfo', ['issuerInfo']),
    ...mapGetters('currentOperator', ['operatorData']),

    currentOperator() {
      return this.operatorData;
    },

    isHSMDisabled() {
      return this.operatorHSMKeys.length === 0;
    },

    getTransactionsIds() {
      const result = this.tList.map(el => el.id);
      return result.toString();
    },

    blockchainType() {
      if (/^algorand.*/i.test(this.deploymentToken.provider)) return 'algorand';
      if (this.deploymentToken.provider === 'aptos') return 'aptos';
      return 'ethereum'; // Default case
    },

    blockchainNetwork() {
      if (/^algorand.*/i.test(this.deploymentToken.provider)) return 'algorand';
      return this.deploymentToken.provider;
    },

    hasMultiSigWalletPendingSignatures() {
      return this.isKeySignMultiSignature && this.multiSigWalletHasPendingSignatures;
    },

    getSignatureTypes() {
      return i18nKeyListConvert(this.signatureTypes, 'text');
    },

    isKeySignOptionSelected() {
      return this.selectedOptionToSign === 'KEYSIGN';
    },

    // 🔹 Updated to use "KEYSIGN"
    isKeySignSingleSignature() {
      return this.selectedOptionToSign === 'KEYSIGN' && this.selectedSignatureType === this.singleSignature;
    },

    isKeySignMultiSignature() {
      return this.selectedOptionToSign === 'KEYSIGN' && this.selectedSignatureType === this.multiSignature && this.selectedMultiSigWallet !== null;
    },

    // 🔹 Updated to use "SWIM"
    isSWIMSelected() {
      return this.selectedOptionToSign === 'SWIM';
    },

    // 🔹 Updated to use "HSM"
    isHSMSelected() {
      return this.selectedOptionToSign === 'HSM';
    },

    // 🔹 Updated to use "RAWSIGN" (Fireblocks)
    isRawSignSelected() {
      return this.selectedOptionToSign === 'RAWSIGN';
    },

    // 🔹 Updated to use "LEDGER"
    isLedgerSelected() {
      return this.selectedOptionToSign === 'LEDGER';
    },

    isSelectedSignatureTypeMultisig() {
      return this.selectedSignatureType === 'multiSignature';
    },

    disableMultiSigWalletSelect() {
      if (this.signatureCount) {
        return true; // 🔹 Disable when signature count exists (MultiSig should be pre-selected)
      }
      return this.multiSigWalletHasPendingSignatures || !this.multiSigWallets || this.multiSigWallets.length === 0;
    },
    // 🔹 Feature Flag System
    availableFeatures() {
      return {
        ...signingFeatures.features,
        ...signingFeatures.overrides[this.blockchainNetwork],
      };
    },

    shouldDisplayRawSign() {
      return this.availableFeatures.RAWSIGN;
    },

    shouldDisplayHSM() {
      return this.availableFeatures.HSM;
    },

    shouldDisplaySWIM() {
      return this.availableFeatures.SWIM;
    },

    shouldDisplayKeySign() {
      return this.availableFeatures.KEYSIGN;
    },

    shouldDisplayLedger() {
      return this.availableFeatures.LEDGER;
    },
  },
  watch: {
    selectedOptionToSign() {
      this.signatureTypeChanged();
    },
  },
  methods: {
    ...mapActions('signatures', [
      'invokeDsProtocol',
      'getClientConfigurations',
      'bulkDsProtocolTransactions',
      'sendTransactionSign',
      'sendBulkDsProtocolTransactionSignature',
      'sendTransactionSignature',
      'getMultisigWalletsByDeploymentId',
      'getMultisigWalletTransactionsStatus',
      'getTokenConfiguration',
      'getOperatorHSMKeys',
      'sendMultiSigLastTransactionSign',
    ]),
    ...mapActions('configToken', ['sendTransactionHash', 'updateBulkDsProtocolTransactionStatus']),

    async init() {
      this.isLoading = true;

      try {
        const { idIssuer: issuerId } = this.$route.params;
        const featureFlags = [BULK_DS_PROTOCOL_TRANSACTIONS_FEATURE_FLAG];

        const featureFlagStates = await this.getClientConfigurations({ issuerId, featureFlags });
        this.bulkTransactionsEnabled = featureFlagStates[BULK_DS_PROTOCOL_TRANSACTIONS_FEATURE_FLAG];

        this.getTransactionsList();
        this.deploymentId = this.transactions[0].deploymentId;
        await this.getMultiSigSetup();

        // 🚀 Ensure HSM Keys are loaded
        await this.fetchHSMKeys();

        // 🔹 Set Signature Type Defaults
        this.updateSignatureTypeDisabled();

        // 🔹 Set Signature Type Defaults
        if (this.signatureCount) {
          // If signature count exists, prioritize multi-sig
          this.selectedSignatureType = this.multiSignature;
          this.selectedMultiSigWallet = this.multiSigStatus.multiSigWalletId;
        } else {
          // Default to Single Signature when no multi-sig setup
          this.selectedSignatureType = this.singleSignature;
        }

        // Disable selector if no multi-sig wallets
        this.shouldDisableMultiSigWalletSelect();

        // 🚀 Dynamically set first available signing option
        this.setDefaultSigningOption();
      } catch (error) {
        const errorText = this.getErrorText(error);
        if (errorText) this.errors.push(errorText);
      } finally {
        this.isLoading = false;
      }
    },

    shouldDisableMultiSigWalletSelect(chainName) {
      if (!multiSigChainAvailability.features.available) {
        return true;
      }

      const chainOverride = multiSigChainAvailability.overrides[chainName];
      if (chainOverride && chainOverride.available === false) {
        return true;
      }

      return (
          this.multiSigWalletHasPendingSignatures
          || !this.multiSigWallets
          || this.multiSigWallets.length === 0
      );
    },

    submitTransactions() {
      this.signAll(this.isLedgerSelected);
    },

    updateSignatureTypeDisabled() {
      this.signatureTypeDisabled = !this.multiSigWallets || this.multiSigWallets.length === 0 || this.multiSigWalletHasPendingSignatures;
    },

    async getMultiSigSetup() {
      this.multiSigWalletHasPendingSignatures = false;
      this.clientTransactionId = this.transactions[0].id;
      if (this.deploymentId) {
        await this.getMultisigWallets(this.deploymentId);
        this.multiSigStatus = await this.getMultiSigWalletStatus(this.deploymentId, this.clientTransactionId, true);
      }
      // Clean State!
      if (this.multiSigStatus) {
        this.multiSigWalletHasPendingSignatures = this.multiSigStatus.signingLock
            && this.multiSigStatus.signatures === 0
            && this.multiSigStatus.threshold >= 1;
      }
      this.displayMultiSigWallets = false;

      this.selectedSignatureType = this.signatureTypes[0].key;
      this.selectedMultiSigWallet = null;
      this.signatureCount = '';

      if (this.multiSigWallets && this.multiSigWallets.length > 0) {
        this.displayMultiSigWallets = false;

        this.selectedSignatureType = this.signatureTypes[0].key;
        if (this.multiSigStatus.multiSigWalletId) {
          this.displayMultiSigWallets = true;
          this.selectedSignatureType = this.signatureTypes[1].key;
          this.selectedMultiSigWallet = this.multiSigStatus.multiSigWalletId;

          this.signatureCount = `${this.multiSigStatus.signatures.toString()}/${this.multiSigStatus.threshold.toString()}`;
          this.displayLastSignMessage = this.multiSigStatus.status === 'unsigned'
              && (this.multiSigStatus.signatures + 1 === this.multiSigStatus.threshold);
        }
      }
    },

    async getMultisigWallets(deploymentId) {
      try {
        const result = await this.getMultisigWalletsByDeploymentId({ deploymentId });
        this.multiSigWalletOptions = result.data.map((wallet) => {
          const text = wallet.signingLock
              ? `${wallet.walletName} - ${this.$t('signatures.signTransactionModal.select.pendingSignatures.text')}`
              : wallet.walletName;
          return { value: wallet.id, text, signingLock: wallet.signingLock };
        });
        this.multiSigWallets = result.data;
      } catch (error) {
        const errorText = this.getErrorText(error);
        if (errorText) this.errors.push(errorText);
      }
    },
    async getMultiSigWalletStatus(deploymentId, clientTransactionId, includeTransactionData) {
      try {
        const result = await this.getMultisigWalletTransactionsStatus({
          deploymentId,
          clientTransactionId,
          includeTransactionData,
        });
        return result.data;
      } catch (error) {
        const errorText = this.getErrorText(error);
        if (errorText) this.errors.push(errorText);
      }
    },

    getTransactionsList() {
      this.errors = [];
      this.privateKey = '';
      this.working = false;
      this.signerAddress = '';
      this.tList = this.transactions;
      const multiSigTransactionList = this.transactions.filter(transaction => transaction.threshold > 1);
      this.multipleMultiSigTransactionsSelected = this.transactions.length > 1 && multiSigTransactionList.length > 0;
    },

    async signAll(isLedger) {
      this.errors = [];
      this.signed = 0;
      this.working = true;
      this.$emit('handleOk', this.tList);

      let identity = '';

      if (this.isLedgerSelected) {
        identity = await this.getAddressFromLedger();
      } else if (this.isHSMSelected && !this.isHSMDisabled) {
        identity = this.$refs.hsmComponent.getSelectedOperatorKey();
      } else if (this.isSWIMSelected) {
        await this.$refs.swimComponent.initWallet();
        try {
          identity = await this.$refs.swimComponent.getSignerAddress();
        } catch (err) {
          this.working = false;
          await this.$refs.swimComponent.disconnect();
          return;
        }
      } else if (this.isRawSignSelected) {
        identity = this.$refs.fireblocksComponent.getSignerAddress();
      } else if (this.isKeySignOptionSelected) {
        identity = this.signerAddress;
      }

      if (!identity) {
        this.working = false;
        this.errors.push(this.$t('signatures.signTransactionModal.message.invalidIdentityError'));
        return;
      }

      if (this.bulkTransactionsEnabled && this.tList?.length > 1) {
        if (this.selectedMultiSigWallet !== null) {
          this.errors.push(this.$t('signatures.signTransactionModal.message.multiSigFeatureNotImplementedBulkTransactions'));
          return;
        }
        this.processBulkDsProtocolTransactions(identity, this.privateKey, isLedger, this.tList)
            .then(() => {
              this.$emit('onSuccess');
              if (!this.errors.length) {
                this.$refs.modalLogic.hide();
              }
            })
            .catch((err) => {
              console.warn('error', err);
              this.errors.push(
                  ...((err && err.response && err.response.body && err.response.body.message)
                      || err.message
                      || this.$t('signatures.signTransactionModal.message.commonError')
                  ).split(/,/g),
              );
            })
            .finally(async () => {
              this.$emit('onSign');
              this.working = false;
              if (this.isSWIMSelected) {
                await this.$refs.swimComponent.disconnect();
              }
            });
      } else {
        async.series(
            this.tList.map(({ id }) => (callback) => {
              if (this.selectedMultiSigWallet === null) {
                this.processTransaction(identity, id, this.privateKey, isLedger)
                    .then(res => callback(null, res))
                    .catch(err => callback(err, null))
                    .finally(() => {
                      this.signed += 1;
                    });
              } else {
                const walletSelected = this.multiSigWallets.find(wallet => wallet.id === this.selectedMultiSigWallet);
                this.processTransactionForMultiSig(identity, id, this.privateKey, isLedger, walletSelected.walletAddress)
                    .then(res => callback(null, res))
                    .catch(err => callback(err, null))
                    .finally(() => {
                      this.signed += 1;
                    });
              }
            }),
        )
            .then(() => {
              this.$emit('onSuccess');
              if (!this.errors.length) {
                this.$refs.modalLogic.hide();
              }
            })
            .catch((err) => {
              console.warn('error', err);
              this.errors.push(
                  ...((err && err.response && err.response.body && err.response.body.message)
                      || err.message
                      || this.$t('signatures.signTransactionModal.message.commonError')
                  ).split(/,/g),
              );
            })
            .finally(async () => {
              this.$emit('onSign');
              this.working = false;
              if (this.isSWIMSelected) {
                await this.$refs.swimComponent.disconnect();
              }
            });
      }
    },

    async getAddressFromLedger() {
      try {
        return await BlockchainTransactionSigner.getLedgerAddress(this.blockchainType);
      } catch (error) {
        console.error(error);
        const signerError = this.handleBlockchainTransactionSignerError(error);
        this.errors.push(
            signerError || error.message || this.$t('signatures.signTransactionModal.message.ledgerConnectionError'),
        );
        this.working = false;
        return null;
      }
    },

    async processBulkDsProtocolTransactions(signerAddress, privateKey, isLedger, transactionsList) {
      const { idIssuer: issuerId, tokenId } = this.$route.params;

      let transactionData;
      let additionalData;
      let bulkDsProtocolTransactionId;
      try {
        const { data: responseData } = await this.bulkDsProtocolTransactions({
          issuerId,
          tokenId,
          signerAddress,
          transactionsList,
        });

        // eslint-disable-next-line prefer-destructuring
        transactionData = responseData.transactionData;
        // eslint-disable-next-line prefer-destructuring
        bulkDsProtocolTransactionId = responseData.bulkDsProtocolTransactionId;
        additionalData = responseData.additionalData || {};
      } catch (error) {
        const errorText = this.getErrorText(error);
        if (errorText) this.errors.push(errorText);
        return;
      }

      const options = SigningUtils.getNetworkOptions(additionalData);
      const unsignedTransaction = typeof transactionData === 'string' ? transactionData || '{}' : JSON.stringify(transactionData || {});
      let signedTransaction = null;

      try {
        if (this.isLedgerSelected) {
          signedTransaction = await BlockchainTransactionSigner.signUsingLedger(this.blockchainType, unsignedTransaction, options);
        } else if (this.isHSMSelected) {
          const { data: signData } = await this.$refs.hsmComponent.sign({ unsignedTx: unsignedTransaction, options });
          signedTransaction = signData.data;
        } else if (this.isRawSignSelected) {
          const {
            unsignedTx,
            signature,
            publicKey,
            txHash,
          } = await this.$refs.fireblocksComponent.sign({ unsignedTx: unsignedTransaction, options });

          if (txHash) {
            await this.updateBulkDsProtocolTransactionStatus({
              issuerId,
              tokenId,
              bulkDsProtocolTransactionId,
              body: { transactionProviderId: txHash, status: 'sent' },
            });
            return;
          }

          signedTransaction = this.blockchainType === 'ethereum' ? signature?.serializedSignature : unsignedTx;
          additionalData = { signature: { publicKey, ...signature } };
        } else if (this.isSWIMSelected) {
          const transactionHash = await this.$refs.swimComponent.signAndSend({ unsignedTx: unsignedTransaction });
          await this.updateBulkDsProtocolTransactionStatus({
            issuerId,
            tokenId,
            bulkDsProtocolTransactionId,
            body: { transactionProviderId: transactionHash, status: 'sent' },
          });
          return;
        } else {
          signedTransaction = await BlockchainTransactionSigner.sign(this.blockchainType, unsignedTransaction, privateKey, options);
        }
      } catch (error) {
        this.errors = [this.$t('signatures.signTransactionModal.message.signFailure', [transactionsList.map(tx => tx.id).toString(), error.message])];
        return;
      }

      if (signedTransaction) {
        const body = {
          transactionData: signedTransaction,
          identity: signerAddress,
          bulkDsProtocolTransactionId,
          additionalData,
        };
        try {
          await this.sendBulkDsProtocolTransactionSignature({ issuerId, tokenId, body });
        } catch (error) {
          const errorText = this.getErrorText(error);
          if (errorText) this.errors.push(errorText);
        }
      }
    },

    async processTransactionForMultiSig(signerAddress, blockchainTransactionId, privateKey, isLedger, multiSigWalletAddress) {
      const { idIssuer: issuerId, tokenId } = this.$route.params;

      let transactionData;
      let additionalData;

      if (this.multiSigStatus && this.multiSigStatus.transactionId !== '') {
        // eslint-disable-next-line prefer-destructuring
        transactionData = this.multiSigStatus.transactionData;
        // eslint-disable-next-line prefer-destructuring
        additionalData = this.multiSigStatus.additionalData;
      } else {
        try {
          const { data: responseData } = await this.invokeDsProtocol({
            issuerId,
            tokenId,
            blockchainTransactionId,
            signerAddress: multiSigWalletAddress,
          });

          // eslint-disable-next-line prefer-destructuring
          transactionData = responseData.transactionData;
          additionalData = responseData.additionalData || {};
        } catch (error) {
          const errorText = this.getErrorText(error);
          if (errorText) this.errors.push(errorText);
          return;
        }
      }

      const unsignedTransaction = typeof transactionData === 'string' ? transactionData || '{}' : JSON.stringify(transactionData || {});
      let signedTransaction = null;
      let sendTransactionSignResult = null;

      try {
        const optionMultiSig = {
          multiSigWalletAddress,
          multiSigiWalletNonce: this.getMultiSigNonce(),
          chain: additionalData.networkType,
          chainId: additionalData.chainId,
          networkId: additionalData.networkId,
          txType: additionalData.txType,
        };

        if (this.isLedgerSelected) {
          signedTransaction = await BlockchainTransactionSigner.signForMultiSigWalletUsingLedger(this.blockchainType, unsignedTransaction, optionMultiSig);
        } else if (this.isHSMSelected) {
          signedTransaction = await this.$refs.hsmComponent.signWithMultiSig({ unsignedTx: unsignedTransaction, ...optionMultiSig });
        } else if (this.isRawSignSelected) {
          signedTransaction = await this.$refs.fireblocksComponent.sign({
            unsignedTx: unsignedTransaction,
            options: optionMultiSig,
          });
        } else if (this.isSWIMSelected) {
          await this.$refs.swimComponent.initWallet();
          signedTransaction = await this.$refs.swimComponent.sign({
            unsignedTx: unsignedTransaction,
            chainId: optionMultiSig.chainId,
            verifyingContractAddress: multiSigWalletAddress,
            verifyingContractNonce: this.getMultiSigNonce(),
          });
        } else {
          signedTransaction = await BlockchainTransactionSigner.signForMultiSigWallet(this.blockchainType, unsignedTransaction, privateKey, optionMultiSig);
        }
      } catch (error) {
        this.errors = [this.$t('signatures.signTransactionModal.message.signFailure', [blockchainTransactionId, error.message])];
        return;
      }

      if (signedTransaction) {
        const additionalDataBody = {
          multiSigTransactionExecution: true,
          multiSigWalletId: this.selectedMultiSigWallet,
          multiSigWalletNonce: this.getMultiSigNonce(),
          clientTransactionId: blockchainTransactionId,
          signature: {
            s: signedTransaction.signature.s,
            r: signedTransaction.signature.r,
            v: signedTransaction.signature.v,
          },
        };

        if (this.blockchainType === 'aptos') {
          additionalDataBody.signature.publicKey = signedTransaction.publicKey;
          additionalDataBody.signature.serializedTransaction = signedTransaction.signature.serializedTransaction;
        }

        const body = {
          identity: signerAddress,
          transactionData: signedTransaction.signedTransaction,
          additionalData: additionalDataBody,
        };

        try {
          if (this.multiSigStatus.multiSigWalletId !== '' && this.multiSigStatus.signatures + 1 === this.multiSigStatus.threshold) {
            sendTransactionSignResult = await this.sendMultiSigLastTransactionSign({
              issuerId,
              tokenId,
              blockchainTransactionId,
              body,
            });
          } else {
            await this.sendTransactionSignature({ issuerId, tokenId, blockchainTransactionId, body });
          }
        } catch (error) {
          const errorText = this.getErrorText(error);
          if (errorText) this.errors.push(errorText);
        }

        if (this.multiSigStatus.signatures + 1 === this.multiSigStatus.threshold && sendTransactionSignResult) {
          await this.processLastMultiSigTransaction(sendTransactionSignResult.data.transactionData, additionalData, isLedger, privateKey, signerAddress, blockchainTransactionId);
        }
      }
    },

    async processLastMultiSigTransaction(transactionData, additionalData, isLedger, privateKey, signerAddress, blockchainTransactionId) {
      const { idIssuer: issuerId, tokenId } = this.$route.params;

      const options = SigningUtils.getNetworkOptions(additionalData);
      const unsignedTransaction = typeof transactionData === 'string' ? transactionData || '{}' : JSON.stringify(transactionData || {});
      let signedTransaction = null;
      try {
        if (isLedger) {
          signedTransaction = await BlockchainTransactionSigner.signUsingLedger(this.blockchainType, unsignedTransaction, options);
        } else if (this.isHSMSelected) {
          const { data: signData } = await this.$refs.hsmComponent.sign({ unsignedTx: unsignedTransaction, options });
          signedTransaction = signData.data;
        } else if (this.isRawSignSelected) {
          const {
            unsignedTx,
            signature,
            publicKey,
            txHash,
          } = await this.$refs.fireblocksComponent.sign({ unsignedTx: unsignedTransaction, options });

          if (txHash) {
            await this.sendTransactionHash({
              deploymentId: this.deploymentId,
              signatureTxId: blockchainTransactionId,
              transactionProviderId: txHash,
            });

            return;
          }

          signedTransaction = this.blockchainType === 'ethereum' ? signature?.serializedSignature : unsignedTx;
          additionalData = {
            signature: {
              publicKey,
              ...signature,
            },
          };
        } else if (this.isSWIMSelected) {
          /**
           * For SWIM operations, when the transaction is signed, the transaction is sent to the blockchain
           * So, we need to get the resulting transaction hash from the blockchain
           * and then send the transaction hash to signature-service
           */
          const transactionHash = await this.$refs.swimComponent.signAndSend({ unsignedTx: unsignedTransaction });

          await this.sendTransactionHash({
            deploymentId: this.deploymentId,
            signatureTxId: blockchainTransactionId,
            transactionProviderId: transactionHash,
          });

          return;
        } else {
          signedTransaction = await BlockchainTransactionSigner.sign(this.blockchainType, unsignedTransaction, privateKey, options);
        }
      } catch (error) {
        if (!isLedger) {
          if (this.isSWIMSelected) {
            this.errors = [this.$t('signatures.signTransactionModal.message.settlementFailed', [blockchainTransactionId, error.code, error.message])];
          } else if (error.message !== `BLOCKCHAIN_${this.blockchainType.toUpperCase()}_NOT_SUPPORTED`) {
            this.errors = [this.$t('signatures.signTransactionModal.message.signFailure', [blockchainTransactionId, error.message])];
          } else {
            this.errors = [this.$t('signatures.signTransactionModal.message.commonError')];
          }
        }
        return;
      }

      if (signedTransaction) {
        const body = { transactionData: signedTransaction, identity: signerAddress, additionalData };
        try {
          await this.sendTransactionSignature({ issuerId, tokenId, blockchainTransactionId, body });
        } catch (error) {
          const errorText = this.getErrorText(error);
          if (errorText) this.errors.push(errorText);
        }
      }
    },

    async processTransaction(signerAddress, blockchainTransactionId, privateKey, isLedger) {
      const { idIssuer: issuerId, tokenId } = this.$route.params;

      let transactionData;
      let additionalData;
      try {
        const { data: responseData } = await this.invokeDsProtocol({
          issuerId,
          tokenId,
          blockchainTransactionId,
          signerAddress,
        });
        // eslint-disable-next-line prefer-destructuring
        transactionData = responseData.transactionData;
        additionalData = responseData.additionalData || {};
      } catch (error) {
        const errorText = this.getErrorText(error);
        if (errorText) this.errors.push(errorText);
        return;
      }

      const options = SigningUtils.getNetworkOptions(additionalData);
      const unsignedTransaction = typeof transactionData === 'string' ? transactionData || '{}' : JSON.stringify(transactionData || {});
      let signedTransaction = null;
      try {
        if (isLedger) {
          signedTransaction = await BlockchainTransactionSigner.signUsingLedger(this.blockchainType, unsignedTransaction, options);
        } else if (this.isHSMSelected) {
          const { data: signData } = await this.$refs.hsmComponent.sign({ unsignedTx: unsignedTransaction, options });
          signedTransaction = signData.data;
        } else if (this.isRawSignSelected) {
          const {
            unsignedTx,
            signature,
            publicKey,
            txHash,
          } = await this.$refs.fireblocksComponent.sign({ unsignedTx: unsignedTransaction, options });

          if (txHash) {
            await this.sendTransactionHash({
              deploymentId: this.deploymentId,
              signatureTxId: blockchainTransactionId,
              transactionProviderId: txHash,
            });

            return;
          }

          signedTransaction = this.blockchainType === 'ethereum' ? signature?.serializedSignature : unsignedTx;

          additionalData = {
            signature: {
              publicKey,
              ...signature,
            },
          };
        } else if (this.isSWIMSelected) {
          /**
           * For SWIM operations, when the transaction is signed, the transaction is sent to the blockchain
           * So, we need to get the resulting transaction hash from the blockchain
           * and then send the transaction hash to signature-service
           */
          const transactionHash = await this.$refs.swimComponent.signAndSend({ unsignedTx: unsignedTransaction });

          await this.sendTransactionHash({
            deploymentId: this.deploymentId,
            signatureTxId: blockchainTransactionId,
            transactionProviderId: transactionHash,
          });

          return;
        } else if (this.isKeySignOptionSelected) {
          signedTransaction = await BlockchainTransactionSigner.sign(this.blockchainType, unsignedTransaction, privateKey, options);
        }
      } catch (error) {
        if (!isLedger) {
          if (this.isSWIMSelected) {
            this.errors = [this.$t('signatures.signTransactionModal.message.settlementFailed', [blockchainTransactionId, error.code, error.message])];
          } else if (error.message !== `BLOCKCHAIN_${this.blockchainType.toUpperCase()}_NOT_SUPPORTED`) {
            this.errors = [this.$t('signatures.signTransactionModal.message.signFailure', [blockchainTransactionId, error.message])];
          } else {
            this.errors = [this.$t('signatures.signTransactionModal.message.commonError')];
          }
        }
        return;
      }

      if (signedTransaction) {
        const body = { transactionData: signedTransaction, identity: signerAddress, additionalData };
        try {
          await this.sendTransactionSignature({ issuerId, tokenId, blockchainTransactionId, body });
        } catch (error) {
          const errorText = this.getErrorText(error);
          if (errorText) this.errors.push(errorText);
        }
      }
    },

    handleBlockchainTransactionSignerError(error) {
      if (!error || !error.message) {
        return;
      }
      const CANNOT_DETECT_LEDGER_ERROR = 'Ledger device was detected but could not establish connection.';
      const COULD_NOT_ESTABLISH_CONNECTION_ERROR = 'Cannot detect a Ledger device. If it is connected, make sure it is unlocked.';

      const errorsMap = {
        [CANNOT_DETECT_LEDGER_ERROR]: this.$t('signatures.signTransactionModal.message.cannotDetectLedgerError'),
        [COULD_NOT_ESTABLISH_CONNECTION_ERROR]: this.$t('signatures.signTransactionModal.message.couldNotEstablishConnectionError'),
      };
      return errorsMap[error.message];
    },

    signatureTypeChanged() {
      this.displayMultiSigWallets = this.selectedSignatureType === this.multiSignature;

      // 🚀 Ensure selector updates correctly
      this.signatureTypeDisabled = !this.multiSigWallets || this.multiSigWallets.length === 0;

      this.errors = [];
    },

    async multiSigChanged() {
      try {
        this.isLoading = true;
        this.multiSigWalletHasPendingSignatures = false;
        this.multiSigStatus = await this.getMultiSigWalletStatus(this.deploymentId, this.clientTransactionId, true);

        if (this.multiSigStatus) {
          this.multiSigWalletHasPendingSignatures = this.multiSigStatus.signingLock;
        }

        if (this.multiSigStatus && this.multiSigStatus.transactionId !== '') {
          this.signatureCount = `${this.multiSigStatus.signatures}/${this.multiSigStatus.threshold}`;
        } else {
          const walletSelected = this.multiSigWallets.find(wallet => wallet.id === this.selectedMultiSigWallet);
          this.signatureCount = `0/${walletSelected.threshold}`;
          this.multiSigWalletHasPendingSignatures = walletSelected.signingLock;
        }
      } catch (error) {
        const errorText = this.getErrorText(error);
        if (errorText) this.errors.push(errorText);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchHSMKeys() {
      try {
        const { data } = await this.getOperatorHSMKeys({ operatorId: this.currentOperator.idUUID });

        if (data.status && data.operatorKeys.length > 0) {
          this.operatorHSMKeys = data.operatorKeys;
          this.selectedOptionToSign = 'HSM'; // Automatically select HSM if available
        }
      } catch (error) {
        console.error('Failed to fetch HSM keys:', error);
        this.errors.push(this.$t('signatures.signTransactionModal.message.hsmFetchError'));
      }
    },

    getErrorText(error) {
      let errorKey = error.response && error.response.data && error.response.data ? error.response.data.error : undefined;
      if (!errorKey) {
        errorKey = error.response && error.response.data && error.response.data ? error.response.data.name : undefined;
      }
      const errorsMap = {
        BLOCKCHAIN_ERROR: this.$t('signatures.signTransactionModal.message.blockchainError'),
        UNAUTHORIZED_WALLET: this.$t('signatures.signTransactionModal.message.unauthorizedWalletError'),
        NOT_ENOUGH_BALANCE: this.$t('signatures.signTransactionModal.message.notEnoughBalanceError'),
      };

      return errorsMap[errorKey];
    },

    setDefaultSigningOption() {
      const signingMethods = [
        { key: 'HSM', enabled: this.shouldDisplayHSM && this.operatorHSMKeys.length > 0 },
        { key: 'SWIM', enabled: this.shouldDisplaySWIM },
        { key: 'RAWSIGN', enabled: this.shouldDisplayRawSign },
        { key: 'KEYSIGN', enabled: this.shouldDisplayKeySign },
        { key: 'LEDGER', enabled: this.shouldDisplayLedger },
      ];

      const firstAvailable = signingMethods.find(method => method.enabled);
      if (firstAvailable) {
        this.selectedOptionToSign = firstAvailable.key;
      }
    },

    getMultiSigNonce() {
      if (this.multiSigStatus && this.multiSigStatus.transactionId !== '') {
        return this.multiSigStatus.nonce;
      }
      const walletSelected = this.multiSigWallets.find(wallet => wallet.id === this.selectedMultiSigWallet);
      return walletSelected.nonce;
    },

    onSuccess() {
      this.$emit('onSuccess');
      this.$refs.modalLogic.hide();
    },
  },
};
</script>

<style scoped>
/* Ensures each signing method is spaced properly */
.b-form-radio {
  display: block;
  margin-bottom: 12px; /* Space between radio buttons */
}

/* Adds consistent padding inside signing components */
.border-light {
  padding: 16px; /* Internal spacing */
  border-radius: 8px; /* Smooth corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow */
}

/* Additional spacing between signing method sections */
.mt-3 {
  margin-top: 12px;
}

.mb-3 {
  margin-bottom: 12px;
}

/* Ensures extra padding inside wallet components */
.ml-4 {
  margin-left: 16px;
}

.p-2 {
  padding: 12px;
}
</style>
