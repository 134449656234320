<template>
  <div>
    <div
      v-if="showSpinner"
      class="text-center my-2 minimum-height"
    >
      <div class="row align-items-center mb-2">
        <div class="col-md-12 text-center">
          <b-spinner class="align-middle" />
        </div>
      </div>
    </div>
    <div v-else>
      <cp-select
        v-model="selectedVault"
        :disabled="vaultsId.length < 1"
        :options="vaultsId"
        class="mr-2"
        name="vaultSelect"
      />
      <div
        v-if="selectedVault"
        class="mt-3"
      >
        <div class="d-flex align-items-center wallet-container">
          <span class="wallet-address text-truncate">{{ selectedWalletAddress }}</span>
          <copyToClipboard :link="selectedWalletAddress" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import CpSelect from '@/components/common/standalone-components/inputs/cp-select.vue';
import copyToClipboard from '@/components/common/copy-to-clipboard.vue';
import { i18nKeyListConvert } from '@/utilities/i18n-util';


export default {
  name: 'CPFireblocks',
  components: { copyToClipboard, CpSelect },
  data() {
    return {
      selectedVault: null,
      showSpinner: true,
      vaultsId: [],
      vaultsWallets: [],
      selectedWalletAddress: '',
    };
  },
  computed: {
    ...mapGetters('currentOperator', ['operatorData']),
    ...mapGetters('configToken', ['getAvailableNetworks']),
    chainId() {
      return this.getAvailableNetworks.find(network => network.id === this.deploymentToken().provider)?.chainId;
    },
  },
  watch: {
    selectedVault() {
      this.selectedWalletAddress = this.getSignerAddress();
    },
  },
  created() {
    Promise.resolve(this.getVaults())
        .catch((err) => {
          this.CALL_ERROR_TOASTER(err);
        }).finally(() => {
      this.showSpinner = false;
    });
  },
  methods: {
    ...mapState('configToken', ['deploymentToken']),
    ...mapActions('signatures', ['getOperatorVaults', 'signRawTransaction']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    async getVaults() {
      const { idUUID } = this.operatorData;

      try {
        const { data: vaults } = await this.getOperatorVaults({ operatorId: idUUID, chainId: this.chainId });

        this.vaultsWallets = vaults.map(vault => ({
          id: vault.id,
          walletAddress: vault.walletAddress,
        }));

        this.vaultsId = i18nKeyListConvert(vaults.map(vault => ({
          key: vault.id,
          text: vault.name,
          value: vault.id,
        })), 'id');

        this.selectedVault = this.vaultsId[0].value;
      } catch (err) {
        this.CALL_ERROR_TOASTER(err);
      }
    },

    async sign({ unsignedTx }) {
      try {
        const { data: signatureResponse } = await this.signRawTransaction({
          vaultAccountId: this.selectedVault,
          chainId: this.chainId.toString(),
          rawTransaction: unsignedTx,
        });

        return {
          unsignedTx: unsignedTx.includes('0x') ? unsignedTx : `0x${unsignedTx}`,
          ...signatureResponse,
        };
      } catch (err) {
        this.CALL_ERROR_TOASTER(err);
      }
    },
    getSignerAddress() {
      const vault = this.vaultsWallets.find(v => v.id === this.selectedVault);
      return vault ? vault.walletAddress : '';
    },
  },
};
</script>

<style scoped>
.wallet-container {
  background-color: #f8f9fa;
  padding: 0.5rem;
  border-radius: 0.25rem;
  max-width: 100%;
}

.wallet-address {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 24px); /* Adjust this value if needed */
}
</style>
